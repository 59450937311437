<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div class="mt-4" style="border-left: 1px solid #878E99; height:1.5rem" />
      <div>
        <h1 class="text-xl mr-4 text-left font-extrabold ml-4 mt-3">
          {{$route.query.name}}
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb
          @onClick="handleBreadcrumbClick($event)"
          :items="breadcrumbs"
        />
      </div>
    </div>

        <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

      <div class="px-3" v-else>
        <card class="p-5 mt-6">
          <div class="border rounded border-romanSilver border-dashed p-3">
            <p class="font-bold mb-5 text-lg">Pension Information</p>
            <div class="flex w-full gap-3">
              <card class="p-3 w-auto">
                <h6 class=" uppercase mb-2 font-bold mb-2 text-xs font-black text-darkPurple">
                  Pfa Name
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ pfaData.pfaName }}
                </h6>
              </card>
              <card class="p-3 ml-2">
                <div class="flex">

                  <div class="flex flex-col">
                    <h6 class="font-semibold mt-2 text-xs font-black text-blueCrayola uppercase">
                      Employer Enrollment Number
                    </h6>
                    <span
                      class="py-1 mr-4 capitalize font-semibold text-sm "
                    >
                      {{
                        pfaData.pfaCode
                      }}
                    </span>
                  </div>
                </div>
              </card>
            </div>
            </div>

        <div class="w-full flex mt-2 gap-5">
          <Card
            class="w-1/3 text-white px-3 pt-6"
            style="background: #3454d1 !important"
          >
            <div class="flex">
              <Icon class-name="text-white " icon-name="union" size="l" />

              <div class="flex flex-col w-1/2 ml-4">
                <span class="font-thin">Total pension deductions</span>
                <span class="text-lg font-bold">
                  {{
                    convertToCurrency(pfaSummary.totalPensionDeduction)
                  }}</span
                >
              </div>

              <div class="border bg-white w-0 mx-4 my-4" />

              <div class="flex flex-col w-1/2 ml-4">
                <div class="flex flex-col">
                  <span class="font-thin">Total Remitted</span>
                  <span class="text-lg font-bold">{{
                    convertToCurrency(pfaSummary.totalRemitted)
                  }}</span>
                </div>

                <div class="flex flex-col mt-2">
                  <span class="font-thin">Total Outstanding</span>
                  <span class="text-lg font-bold">{{
                    convertToCurrency(pfaSummary.totalOutstanding)
                  }}</span>
                </div>
              </div>
            </div>
          </Card>

          <Card
            class="w-2/5 text-white px-3 flex"
            style="
              background: #321c3b !important;
              box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06),
                0px 1px 3px rgba(8, 7, 8, 0.1);
              border-radius: 8px;
            "
          >
            <div class="flex">
              <Icon icon-name="payrunSheet" class="" size="l" />
              <div class="flex flex-col ml-2 my-8">
                <span class="font-semibold text-xs uppercase"
                  >Total Employee Contributions</span
                >
                <span class="font-bold text-lg"
                  >{{
                    convertToCurrency(pfaSummary.totalEmployeeContributions)
                  }}
                </span>
              </div>
            </div>
            <div class="border bg-romanSilver w-0 mx-4 my-4" />
            <div class="flex">
              <Icon icon-name="wallet" class="" size="l" />
              <div class="flex flex-col ml-4 my-8">
                <span class="font-semibold text-xs uppercase"
                  >Total Employer Contributions</span
                >
                <span class="font-bold text-lg">{{
                  convertToCurrency(pfaSummary.totalEmployerContributions)
                }}</span>
              </div>
            </div>
          </Card>

          <Card
            class="w-1/3 px-3 flex text-white"
            style="
              background: #13b56a !important;
              box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06),
                0px 1px 3px rgba(8, 7, 8, 0.1);
              border-radius: 8px;
            "
          >
            <Icon icon-name="employee_payrun" class-name="ml-4" size="m" />

            <div class="flex flex-col my-8 ml-6">
              <span class="font-semibold uppercase text-xs"
                >Pensionable employees</span
              >
              <span class="font-bold text-lg pt-2">{{
                pfaSummary.employeeCount
              }}</span>
            </div>
          </Card>
        </div>

            </card>
            </div>

    <div class="px-3" v-if="!loading">
      <card class="p-5 mt-6 ">
        <template v-slot:footer>
          <CardFooter>
            <template v-slot:search>
              <SearchInput
                placeholder="Search"
                class="search"
                style="width : 300px; margin-top: 0.1rem;"
              />
            </template>
          </CardFooter>
        </template>
      </card>
      <div class="w-full mt-10">
            <sTable
              :headers="pensionsHeader"
              :items="payrunData"
              aria-label="pension table"
              class="w-full mt-1"
              :has-checkbox="false"
              :has-number="false"
              :loading="loading"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.status">
                  <Badge
                    label="Remitted"
                    variant="primary"
                    background-color="rgba(19, 181, 106, 0.08)"
                    color="#13B56A"
                    style="padding: 8px; height: 33px; font-weight: 600; font-size: 14px"
                    v-if="item.data.status === 'paid'"
                  />
                  <Badge
                    label="Outstanding"
                    variant="primary"
                    background-color="rgba(234, 60, 83, 0.08)"
                    color="#EA3C53"
                    style="padding: 8px; height: 33px; font-weight: 600; font-size: 14px"
                    v-else
                  />
                </div>
                <div v-else-if="item.id">
                  <div
                    v-feature-request
                    @click="
                      $router.push({
                        name: 'ViewEmployees',
                        params: { id: item.data.id, idNo: item.data.idNo },
                        query: {
                          name: $route.query.name,
                        }
                      })
                    "
                    class="cursor-pointer h-8 flex"
                  >
                    <icon
                      icon-name="icon-eye"
                      size="xsm"
                      class="-mt-1 -ml-2 text-blueCrayola"
                      style="padding: 3px 10px; width: 36px; height: 36px"
                    />
                  </div>
                </div>
              </template>
            </sTable>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PensionDetails",
  components: {
    Breadcrumb: () =>
      import("@scelloo/cloudenly-ui/src/components/bread-crumb"),
    Card: () => import("@/components/Card"),
    CardFooter: () => import("@/components/CardFooter"),
    SearchInput: () =>
      import("@scelloo/cloudenly-ui/src/components/search-input"),
    BackButton: () =>
      import("@scelloo/cloudenly-ui/src/components/back-button"),
    Icon: () => import("@/components/Icon"),
    STable: () => import("@scelloo/cloudenly-ui/src/components/table"),
    Badge: () => import("@scelloo/cloudenly-ui/src/components/badge"),
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "payroll",
          id: "Payroll"
        },
        {
          disabled: false,
          text: "Compliance",
          href: "PayRoll Pension",
          id: "pensions"
        },
        {
          disabled: false,
          text: "Pension",
          href: "ViewPension",
          id: "View Pension"
        },
        {
          disabled: false,
          text: this.$route.query.name,
          href: this.$route.query.name,
          id: "View Pension"
        },
      ],
      pensionsHeader: [
        { title: "Payrun Cycle", value: "payrunCycle" },
        { title: "Payrun Date", value: "payrunDate" },
        { title: "Payrun Id No", value: "idNo" },
        { title: "No of Employees", value: "employeesCount" },
        { title: "Employee Deductions", value: "employeesDeductions" },
        { title: "Employer Contributions", value: "employersContributions" },
        { title: "Total Pension", value: "totalPension" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      payrunData: [],
      pfaData: {
        pfaName: "",
        pfaCode: ""
      },
      pfaSummary: {
        totalPensionDeduction: 0,
        totalRemitted: 0,
        totalOutstanding: 0,
        totalEmployerContributions: 0,
        employeeCount: 0,
        totalEmployeeContributions: 0,
      },
      loading: true
    };
  },
  methods: {
    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    getRemitPayrun() {
      this.$_getRemitPayrunPfa({
        pfaId: this.$route.params.id,
      }).then(result => {
        const pfaDetails = result.data.pfa;
        const { summary } = result.data;
        const payrunData = result.data.payrunCycles;

        this.pfaData.pfaName = pfaDetails.pfaInfomation.name;
        this.pfaData.pfaCode = pfaDetails.orgPFA.orgPfaNo;
        this.tableData = result.data.payrun;

        this.pfaSummary.totalEmployerContributions = summary.totalEmployerContributions;
        this.pfaSummary.employeeCount = summary.employeeCount;
        this.pfaSummary.totalRemitted = summary.totalRemitted;
        this.pfaSummary.totalOutstanding = summary.totalOutstanding;
        this.pfaSummary.totalEmployeeContributions = summary.totalEmployeeContributions;
        this.pfaSummary.totalPensionDeduction = summary.totalPensionDeduction;

          payrunData.forEach((data) => {
            this.payrunData.push({
              id: this.$route.params.id,
              payrunCycle: `${this.$DATEFORMAT(
                new Date(`${data.payrun.year}-${data.payrun.month}-01`),
                `MMMM yyyy`
              )} Cycle`,
              payrunDate: data.payrun.paidAt ? `${this.$DATEFORMAT(
                new Date(`${data.payrun.paidAt}`),
                `MMMM dd, yyyy`
              )}` : `${this.$DATEFORMAT(
                new Date(this.resetDate(`${data.payrun.payDate}`)),
                `MMMM dd, yyyy`
              )}`,
              idNo: data.payrun.payrunNo,
              employeesCount: data.employeeCount,
              employeesDeductions: `${this.convertToCurrency(
                data.employeeContributions
              )}`,
              employersContributions: `${this.convertToCurrency(
                data.employerContributions
              )}`,
              totalPension: `${this.convertToCurrency(
                data.totalPension
              )}`,
              status: `${data.status}`,
            });
          });

        this.loading = false;
      });
    },
    async viewRemittance() {
      try {
        await this.$handlePrivilege("pension", "viewCurrentPensionRemittance");
      } catch (error) {
        this.$router.push({ name: "ErrorRestricted" });
      }
    },
    async remitPension() {
      try {
        await this.$handlePrivilege("pension", "remittPension");
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    handleBreadcrumbClick(event) {
      if (event === "ViewPension") {
        this.$router.push({
          name: event,
          query: { name: this.$route.query.name }
        });
      } else {
        this.$router.push({
          name: event
        });
      }
    }
  },
  async mounted() {
    this.getRemitPayrun();
  }
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
